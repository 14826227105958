<template>
  <nav
    class="
      navbar navbar-expand navbar-light
      bg-white
      topbar
      mb-4
      static-top
      shadow
    "
  >
    <!-- Sidebar Toggle (Topbar) -->
    <button
      id="sidebarToggleTop"
      class="btn btn-link d-md-none rounded-circle mr-3"
    >
      <i class="fa fa-bars"></i>
    </button>

    <a href="#" @click.prevent="$router.go(-1)" v-if="$route.name != 'admin.home'"><i class="fa fa-arrow-left mr-2"></i>Back</a>

    <!-- Topbar Search -->
    <form
      class="
        d-none d-sm-inline-block
        form-inline
        mr-auto
        ml-md-3
        my-2 my-md-0
        mw-100
        navbar-search
      "
      v-if="1 == 0"
    >
      <div class="input-group">
        <input
          type="text"
          class="form-control bg-light border-0 small"
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-addon2"
        />
        <div class="input-group-append">
          <button class="btn btn-primary" type="button">
            <i class="fas fa-search fa-sm"></i>
          </button>
        </div>
      </div>
    </form>

    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown no-arrow mx-1">
        <router-link
          class="nav-link text-danger"
          :to="{ name: 'admin.conference.create' }"
        >
          <i class="fas fa-video fa-fw mr-2"></i>
          <!-- Counter - Messages -->
          Go Live
        </router-link>
      </li>

      <!-- Nav Item - Messages -->
      <li class="nav-item dropdown no-arrow mx-1">
        <router-link
          class="nav-link text-gray-600"
          :to="{ name: 'admin.messages' }"
        >
          <i class="fas fa-envelope fa-fw"></i>
          <!-- Counter - Messages -->
          <span class="badge badge-danger badge-counter" v-if="user.unread_messages_count > 0">{{ user.unread_messages_count }}</span>
        </router-link>
      </li>

      <div class="topbar-divider d-none d-sm-block"></div>

      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <a
          class="nav-link dropdown-toggle text-gray-700"
          href="#"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="mr-2 d-none d-lg-inline text-gray-600 small"
            >{{ first_name }}</span
          >
          <img
            class="img-profile rounded-circle"
            :src="user.avatar"
          />
        </a>
        <!-- Dropdown - User Information -->
        <div
          class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="userDropdown"
        >
          <router-link class="dropdown-item" :to="{ name: 'admin.my-account' }">
            <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-700"></i>
            Profile
          </router-link>
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="logout"
          >
            <i class="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-700"></i>
            Logout
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$axios.post("/api/v1/logout").then(() => {
        this.$auth.removeToken();
        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    },
  },

  computed: {
    first_name() {
      return this.$store.getters.getAuthUser.name.split(" ")[0];
    },

    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>