<template>
  <div>
    <div v-if="error == null" id="sb-admin-theme">
      <!-- Page Wrapper -->
      <div id="wrapper">
        <!-- Sidebar -->
        <side-bar />
        <!-- End of Sidebar -->

        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">
          <!-- Main Content -->
          <div id="content">
            <!-- Topbar -->
            <app-header />
            <!-- End of Topbar -->

            <!-- Begin Page Content -->
            <div class="container-fluid">
              <!-- Page Heading -->
              <router-view />
            </div>
            <!-- /.container-fluid -->
          </div>
          <!-- End of Main Content -->

          <!-- Footer -->
          <footer class="sticky-footer bg-white">
            <div class="container my-auto">
              <div class="copyright text-center my-auto">
                <span>Copyright &copy; MyForexGlobal 2023</span>
              </div>
            </div>
          </footer>
          <!-- End of Footer -->
        </div>
        <!-- End of Content Wrapper -->
      </div>
      <!-- End of Page Wrapper -->

      <!-- Scroll to Top Button-->
      <a class="scroll-to-top rounded" href="#page-top">
        <i class="fas fa-angle-up"></i>
      </a>
    </div>
    <template v-if="error == 404">
      <error-404 />
    </template>
    <template v-if="error == 403">
      <error-403 />
    </template>
    <template v-if="error == 500">
      <error-500 />
    </template>
  </div>
</template>

<script>
import SideBar from './admin/components/SideBar.vue'
import AppHeader from './admin/components/AppHeader.vue'
import "@/assets/js/admin";

export default {
  components: {
    SideBar, AppHeader
  },

  computed: {
    error() {
      return this.$store.getters.getError;
    },
  },
};
</script>