<template>
  <div>
    <ul
      class="
        navbar-nav
        bg-gradient-secondary
        sidebar sidebar-dark
        accordion
        text-white
      "
      id="accordionSidebar"
    >
      <!-- Sidebar - Brand -->
      <router-link
        class="sidebar-brand d-flex align-items-center justify-content-center"
        :to="{ name: 'admin.home' }"
      >
        <img src="/logo.png" alt="logo" />
      </router-link>

      <!-- Divider -->
      <hr class="sidebar-divider my-0" />

      <!-- Nav Item - Dashboard -->
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.home' }">
          <i class="fas fa-fw fa-dashboard"></i>
          <span>Dashboard</span></router-link
        >
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider" />

      <!-- Heading -->
      <div class="sidebar-heading">Content</div>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.categories.index' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Categories</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.discussions.index' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Discussions</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.conference.index' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Daily Conference</span></router-link
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link collapsed"
          href="#"
          data-toggle="collapse"
          data-target="#collapsePages"
          aria-expanded="true"
          aria-controls="collapsePages"
        >
          <i class="fas fa-fw fa-file"></i>
          <span>Site Content</span>
        </a>
        <div
          id="collapsePages"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionSidebar"
        >
          <div class="bg-white py-2 collapse-inner rounded">
            <router-link class="collapse-item" :to="{ name: 'siteadmin.public.home' }" target="_blank">Website</router-link>
            <router-link class="collapse-item" :to="{ name: 'admin.pages.get-funded' }">Get Funded</router-link>
            <router-link class="collapse-item" :to="{ name: 'admin.pages.top-brokers' }">Top Brokers</router-link>
            <router-link class="collapse-item" :to="{ name: 'admin.pages.partners' }">Partners</router-link>
          </div>
        </div>
      </li>

      <hr class="sidebar-divider" />
      <div class="sidebar-heading">Learning</div>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.academy.index' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Academy</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'admin.elite-videos.index' }"
        >
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Elite Videos</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.correlation.index' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Correlation Analysis</span></router-link
        >
      </li>
      <hr class="sidebar-divider" />
      <div class="sidebar-heading">Administration</div>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.users.index' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>User Management</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.pricing.index' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Pricing</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.discounts.index' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Discounts</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.promotions.index' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Promotions</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'admin.transactions.index' }"
        >
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Transactions</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'admin.announcements.index' }"
        >
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Announcements</span></router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'admin.telegram' }">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Telegram</span></router-link
        >
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider d-none d-md-block" />

      <!-- Sidebar Toggler (Sidebar) -->
      <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$axios.post("/api/v1/logout").then(() => {
        this.$auth.removeToken();
        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  .nav-item .nav-link {
    color: #fff;

    i {
      margin-right: 1rem;
      color: rgba(#fff, 0.8);
    }
  }
}
</style>
